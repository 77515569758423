import { GetStaticProps } from 'next';

import { getStaticProps as dynamicGetStaticProps } from 'pages/[[...slug]]';
import DynamicPage from 'pages/[[...slug]]';

export const getStaticProps: GetStaticProps = async () => {
  const contentPageProps = await dynamicGetStaticProps({
    params: { slug: ['404-notfound'] }
  });

  return {
    ...contentPageProps,
    revalidate: 10
  };
};

export default DynamicPage;
